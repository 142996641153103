import React from 'react';
import './Top.scss';
import { Toggle } from "../../../Components/Toggle/Toggle";
import { StyleNode } from "../../../Components/ThemeNodes/StyleNode";
export var Top = function () {
    return (React.createElement(StyleNode, { BEMClass: "top" },
        React.createElement(StyleNode, { BEMClass: "top__logo" }, "Galen Kistler"),
        React.createElement(StyleNode, { BEMClass: "top__toggle" },
            React.createElement(Toggle, null))));
};
