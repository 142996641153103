import React from 'react';
import './Accordion.scss';
import { StyleNode } from "../ThemeNodes/StyleNode";
export var Accordion = function (_a) {
    var items = _a.items, company = _a.company, location = _a.location, title = _a.title, date = _a.date;
    return (React.createElement(StyleNode, { BEMClass: "accordion" },
        React.createElement("div", { className: "accordion__title" },
            React.createElement(StyleNode, { BEMClass: "accordion__title__company" }, company),
            React.createElement(StyleNode, { BEMClass: "accordion__title__title" }, title)),
        React.createElement(StyleNode, { BEMClass: "accordion__date" }, date),
        React.createElement(StyleNode, { BEMClass: "accordion__items" }, items.map(function (item, index) {
            return (React.createElement(StyleNode, { key: index, BEMClass: "accordion__item" }, item));
        }))));
};
