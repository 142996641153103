import React from 'react';
import './Header.scss';
import { Title } from "./Title/Title";
import { Top } from "./Top/Top";
import { StyleNode } from "../../Components/ThemeNodes/StyleNode";
export var Header = function () {
    return (React.createElement(StyleNode, { BEMClass: "header" },
        React.createElement(Top, null),
        React.createElement(Title, null)));
};
