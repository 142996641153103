import React from 'react';
import './Card.scss';
import { StyleNode } from "../ThemeNodes/StyleNode";
export var Card = function (_a) {
    var Icon = _a.icon, title = _a.title, copy = _a.copy;
    return (React.createElement(StyleNode, { BEMClass: "card" },
        React.createElement(StyleNode, { BEMClass: "card__icon" },
            React.createElement(Icon, { className: "card__icon__svg" })),
        React.createElement("div", { className: "card__content-wrap" },
            React.createElement(StyleNode, { BEMClass: "card__title" }, title),
            React.createElement(StyleNode, { BEMClass: "card__copy" }, copy))));
};
