import React from 'react';
import './Main.scss';
import { StyleNode } from "../ThemeNodes/StyleNode";
import { Header } from "../../Partials/Header/Header";
import { Overview } from "../../Partials/Overview/Overview";
import { Logos } from "../../Partials/Logos/Logos";
import { Experience } from "../../Partials/Experience/Experience";
import { Footer } from "../../Partials/Footer/Footer";
// images
import * as Aws from "../../Images/aws.svg";
import * as css from "../../Images/css3.svg";
import * as github from "../../Images/github.svg";
import * as html from "../../Images/html5.svg";
import * as mysql from "../../Images/mysql.svg";
import * as node from "../../Images/nodejs.svg";
import * as php from "../../Images/php.svg";
import * as react from "../../Images/react.svg";
import * as ts from "../../Images/typescript.svg";
import * as webpack from "../../Images/webpack.svg";
export var Main = function (_a) {
    return (React.createElement(StyleNode, { BEMClass: "main" },
        React.createElement(Header, null),
        React.createElement(Overview, null),
        React.createElement(Logos, { logos: [
                ts.default,
                react.default,
                php.default,
                mysql.default,
                webpack.default,
                html.default,
                css.default,
                Aws.default,
                node.default,
                github.default,
            ] }),
        React.createElement(Experience, null),
        React.createElement(Footer, null)));
};
