var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { DarkThemeName, LightThemeName, useTheme } from "../../Contexts/ThemeContext";
export var StyleNode = function (props) {
    var _a;
    var theme = useTheme();
    var isTextNode = typeof props.children === 'string';
    // Destructure the object to remove non HTML compliant attributes from our props
    var BEMClass = props.BEMClass, HTMLProps = __rest(props, ["BEMClass"]);
    var themeClassNames = 
    // Passed in class name, e.g. `.element`
    BEMClass + ' ' +
        // Passed in class name with theme modifier, e.g. `.element--light`
        BEMClass + '--' + (theme === null || theme === void 0 ? void 0 : theme.theme) + ' ' +
        // If the node is a text-node, give it --text modifier, e.g. `.element--text`
        (isTextNode ? (BEMClass + '--text') : '');
    /**
     * This is a "break" in BEM convention to include a namespace of an element above the block, however this is a requirement of the child that the parent is relatively positioned
     * So I'm using this as a solution until I decide if this is better/worse then inlining the style.
     * @param isTextNode
     */
    var getTextNodeParentClassNames = function (isTextNode) {
        return isTextNode ? 'text-node__parent' : '';
    };
    /**
     * So what is going on with this render function?
     * In order to support style-switching feature, I'm using two monospace fonts, and duplicating all text DOM nodes, one for each font.
     * The reason of doing this (instead of changing the font style of the same DOM nodes), is to get around the limitations around animating font changes,
     * so instead we toggle the opacity of each node, and absolutely position one of the nodes (arbitrarily?).
     * This requires setting a relative position on the parent of the text node, or you'll see some differences in word-wrapping when the absolutely positioned text is displayed (causing jumps in content).
     *
     * @todo determine if it's worth unmounting the unused nodes??
     * aka what is the cost of mounting/unmounting these text nodes?
     */
    return (React.createElement("div", __assign({}, HTMLProps, { className: themeClassNames + ' ' + ((_a = HTMLProps.className) !== null && _a !== void 0 ? _a : '') }),
        !isTextNode && props.children,
        isTextNode &&
            React.createElement("div", { className: themeClassNames + ' ' + getTextNodeParentClassNames(isTextNode) },
                React.createElement("div", { className: ("text-node ") + ("text-node") + ((theme === null || theme === void 0 ? void 0 : theme.theme) === LightThemeName ? '--active ' : (" text-node ")) +
                        ("text-node--" + LightThemeName) }, props.children),
                React.createElement("div", { className: ("text-node ") + ("text-node") + ((theme === null || theme === void 0 ? void 0 : theme.theme) === DarkThemeName ? '--active ' : (" text-node ")) +
                        ("text-node--" + DarkThemeName) }, props.children))));
};
