import React from 'react';
import './Overview.scss';
import { Card } from "../../Components/Card/Card";
import { StyleNode } from "../../Components/ThemeNodes/StyleNode";
// Images
import * as Universal from '../../Images/universal.svg';
import * as Intuitive from '../../Images/intuitive.svg';
import * as Collaborative from '../../Images/collaborative.svg';
import * as Maintainable from '../../Images/maintainable.svg';
export var Overview = function () {
    return (React.createElement(StyleNode, { BEMClass: "overview" },
        React.createElement(Card, { icon: Universal.default, title: "Universal", copy: "The web should be accessible to everyone." }),
        React.createElement(Card, { icon: Intuitive.default, title: "Intuitive", copy: "I love to build applications that users want." }),
        React.createElement(Card, { icon: Collaborative.default, title: "Collaborative", copy: "Design, product, and development teams don’t work in a bubble." }),
        React.createElement(Card, { icon: Maintainable.default, title: "Maintainable", copy: "Support isn’t such a chore if the code is well organized and tested." })));
};
