import React from 'react';
import './Toggle.scss';
import { LabelNode } from "../ThemeNodes/LabelNode";
import { StyleNode } from "../ThemeNodes/StyleNode";
import { DarkThemeName, LightThemeName, useTheme } from "../../Contexts/ThemeContext";
// This toggle element will flip the theme context value between light and dark values.
export var Toggle = function () {
    // "!" will tell the TypeScript compiler that its return value won't be undefined
    var _a = useTheme(), theme = _a.theme, setTheme = _a.setTheme;
    // Set theme value on input value change
    var onChange = function () {
        setTheme(theme === LightThemeName ? DarkThemeName : LightThemeName);
    };
    return (React.createElement(LabelNode, { className: "toggle" },
        React.createElement("input", { onChange: function () {
                onChange();
            }, type: "checkbox", className: "toggle__input" }),
        React.createElement(StyleNode, { BEMClass: "toggle__slider" })));
};
