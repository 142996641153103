import React from "react";
// Theme values
export var LightThemeName = 'light';
export var DarkThemeName = 'dark';
// Initially undefined, until the <ThemeProvider> component is mounted
var AppThemeContext = React.createContext(undefined);
export var ThemeProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState(LightThemeName), theme = _b[0], setTheme = _b[1];
    // On mount, set default value from undefined
    React.useEffect(function () {
        setTheme(LightThemeName);
    }, []);
    return (React.createElement(AppThemeContext.Provider, { value: { theme: theme, setTheme: setTheme } }, children));
};
export var useTheme = function () { return React.useContext(AppThemeContext); };
