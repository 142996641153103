import React, { useState } from 'react';
import './Logos.scss';
import { StyleNode } from "../../Components/ThemeNodes/StyleNode";
import ReactInterval from 'react-interval';
// this number is arbitrary
var maxNumberOfSlides = 10;
export var Logos = function (_a) {
    var logos = _a.logos;
    var getNewEmptyArray = function () {
        return new Array(logos === null || logos === void 0 ? void 0 : logos.length).fill(false);
    };
    var getActiveClass = function (index) {
        return isIndexActive(index) ? 'active' : '';
    };
    var isIndexActive = function (index) {
        var activeLogo = activeLogos[index];
        if (typeof activeLogo !== 'boolean') {
            throw Error("Array index out of bounds!");
        }
        return activeLogos[index];
    };
    var updateLogos = function () {
        var _a;
        setActiveLogos(getNewEmptyArray().map(function (el, index) {
            return index === offSet;
        }));
        // Increment the skip and keep it smaller then array length
        setOffset((offSet + 1) % ((_a = logos === null || logos === void 0 ? void 0 : logos.length) !== null && _a !== void 0 ? _a : maxNumberOfSlides));
    };
    var initialLogoState = getNewEmptyArray();
    var _b = useState(initialLogoState), activeLogos = _b[0], setActiveLogos = _b[1];
    var _c = useState(0), offSet = _c[0], setOffset = _c[1];
    return (React.createElement(StyleNode, { BEMClass: "logos" },
        React.createElement(ReactInterval, { timeout: 1750, enabled: true, callback: updateLogos }),
        logos && logos.map(function (Logo, index) {
            return (React.createElement(StyleNode, { key: index, BEMClass: "logos__logo", className: "" + getActiveClass(index) },
                React.createElement(Logo, { className: "logos__logo__svg" })));
        })));
};
