import React from 'react';
import './App.scss';
import { ThemeProvider } from "./Contexts/ThemeContext";
import { Main } from "./Components/Main/Main";
var App = function () {
    return (React.createElement("div", { className: "App app-0.0.1" },
        React.createElement(ThemeProvider, null,
            React.createElement(Main, null))));
};
export default App;
