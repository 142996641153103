import './Footer.scss';
import React from "react";
import * as Github from "../../Images/github.svg";
import * as Linkedin from "../../Images/linkedin.svg";
import { StyleNode } from "../../Components/ThemeNodes/StyleNode";
export var Footer = function () {
    return (React.createElement(StyleNode, { BEMClass: "footer" },
        React.createElement("a", { className: "footer__icon", target: "_blank", href: "https://github.com/tkin1235/gtk" },
            React.createElement(Github.default, null)),
        React.createElement("a", { className: "footer__icon", target: "_blank", href: "https://www.linkedin.com/in/galen-kistler-454ba364/" },
            React.createElement(Linkedin.default, null))));
};
