var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTheme } from "../../Contexts/ThemeContext";
export var LabelNode = function (props) {
    var context = useTheme();
    var themeClassNames = props.className + ' ' + props.className + '--' + (context === null || context === void 0 ? void 0 : context.theme);
    return (React.createElement("label", __assign({}, props, { className: themeClassNames }), props.children));
};
